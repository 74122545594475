import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import {Observable} from 'rxjs';

/* USAGE
getResumeData(){
    let dataURL = './assets/data/resume.json';
    this.jsonService.get( dataURL,  {"responseType":"text"}  ).subscribe( data=>{
       this.resume = JSON.parse( data );
    }, err=>{
      this.error = 'Error : ' + err.message;
    } );
  } //end of method
*/

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private http: HttpClient) { }

  get( url, responseType ): Observable<any>{
    return this.http.get(url, responseType );
  }//end of method


}
