import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs';


@Injectable({
  providedIn: 'root'
})

export class PhpService {

  constructor( private http: HttpClient ) { }

  get( url, responseType): Observable<any>{
    
    return this.http.get(url, responseType);
  }

}
