import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {PhpService} from './services/php.service'
import {JsonService} from './services/json.service'
import { HttpClientModule } from '@angular/common/http'
import {FormsModule} from '@angular/forms'
import {OwlModule} from 'ngx-owl-carousel'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent
  ],
  imports: [
    OwlModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
    
  ],
  providers: [
    PhpService,
    JsonService
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
