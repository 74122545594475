<!DOCTYPE html> 
<html lang="en"> 


    <body> 

        <!-- ======= Header ======= -->  
        <nav class="navbar navbar-expand-lg navbar-light  fixed-top" data-pg-collapsed> 
            <a data-aos="zoom-in" class="navbar-brand d-flex align-items-center" > <img class="mr-3" style=" width:22px; height:36px;" src="./assets/pinegrow/img/logo.png" > St. Luke's Hospital, Anua </a> 
            <button data-aos="zoom-in" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler24" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                <span class="navbar-toggler-icon"></span> 
            </button>     

            <div class="collapse navbar-collapse" id="navbarToggler24"> 
                <ul data-aos="zoom-in" class="navbar-nav mr-auto mt-2 mt-lg-0"> 
                    <li class="nav-item " > 
                        <a [ngClass]="{'active': activeTap=='#hero'}" class="nav-link" (click)="scrollTo('#hero')" style="cursor: pointer;">Home <span class="sr-only">(current)</span></a> 
                    </li>             
                    <li class="nav-item" > 
                        <a [ngClass]="{'active': activeTap=='#services'}" class="nav-link" (click)="scrollTo('#services')" style="cursor: pointer;">Services</a> 
                    </li> 

                    <li class="nav-item " > 
                        <a [ngClass]="{'active': activeTap=='#departments'}" class="nav-link" (click)="scrollTo('#departments')" style="cursor: pointer;">Departments <span class="sr-only">(current)</span></a> 
                    </li>  

                    <li class="nav-item " > 
                        <a [ngClass]="{'active': activeTap=='#gallery'}" class="nav-link" (click)="scrollTo('#gallery')" style="cursor: pointer;">Gallery <span class="sr-only">(current)</span></a> 
                    </li>  

                    <li class="nav-item " > 
                        <a [ngClass]="{'active': activeTap=='#projects'}" class="nav-link" (click)="scrollTo('#projects')" style="cursor: pointer;">Projects <span class="sr-only">(current)</span></a> 
                    </li>  

                    <li class="nav-item " > 
                            <a [ngClass]="{'active': activeTap=='#friends'}" class="nav-link" (click)="scrollTo('#friends')" style="cursor: pointer;">Friends of St. Luke’s <span class="sr-only">(current)</span></a> 
                        </li>  

                    <li class="nav-item " > 
                        <a [ngClass]="{'active': activeTap=='#about'}" class="nav-link" (click)="scrollTo('#about')" style="cursor: pointer;">About <span class="sr-only">(current)</span></a> 
                    </li>  

                    <li class="nav-item " > 
                        <a [ngClass]="{'active': activeTap=='#contact'}" class="nav-link" (click)="scrollTo('#contact')" style="cursor: pointer;">Contact us <span class="sr-only">(current)</span></a> 
                    </li>  

                </ul>
                
                
                <a data-aos="zoom-in" (click)="scrollTo('#donation')" class="ny-btn scrollto mr-0 pr-2 pl-2">Donate</a>
                <a data-aos="zoom-in" (click)="scrollTo('#birth')" class="ny-btn scrollto ml-1 pr-2 pl-2">Birth record</a>
           

            </div>    
        </nav>
        <!--END OF HEADER-->
        

        <!-- End Header -->         
        <!-- ======= Hero Section ======= -->         
        <section id="hero" data-aos="zoom-in"> 
            <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel"> 
                <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>                 
                <div class="carousel-inner" role="listbox"> 
                    <!-- Slide 1 -->                     
                    <div class="carousel-item active" style="background-image: url(assets/pinegrow/img/slide/slide-1.jpg)"> 
                        <div class="container"> 
                            <h2 class="pink"><span>ST. LUKE'S HOSPITAL, ANUA </span></h2> 
                            <h2 style="font-size:1.2rem; color:var(--pink-color); margin-bottom:5px;">CATHOLIC DIOCESE OF UYO</h2> 
                            <h2 style="font-size:1.0rem;">(since 1937)</h2> 
                            <p class="pl-5 pr-5"> St. Lukes hospital is one of the foremost Missionary Hospitals in in South-South and South-East Nigeria. Its was established in 1937 by the Medical Missionaries of Mary with an initial number of twelve beds. Since then, it has... </p> 
                            <a (click)="scrollTo('#about')"  class="btn-get-started scrollto">Read More</a> 
                        </div>                         
                    </div>                     
                    <!-- Slide 2 -->                     
                    <div class="carousel-item" style="background-image: url(assets/pinegrow/img/slide/slide-2.jpg)"> 
                        <div class="container"> 
                            <h2 class="pink">ST. LUKE'S HOSPITAL, ANUA</h2> 
                            <h2 style="font-size:0.8rem; color: var(--blue-color)">(NHIS ACCREDITED HOSPITAL)</h2> 
                            <p class="pl-5 pr-5">St. Lukes hospital is one of the foremost Missionary Hospitals in in South-South and South-East Nigeria. Its was established in 1937 by the Medical Missionaries of Mary with an initial number of twelve beds. Since then, it has... </p> 
                            <a  (click)="scrollTo('#about')"  class="btn-get-started scrollto">Read More</a> 
                        </div>                         
                    </div>                     
                    <!-- Slide 3 -->                     
                    <div class="carousel-item" style="background-image: url(assets/pinegrow/img/slide/slide-3.jpg)"> 
                        <div class="container"> 
                            <h2 class="pink">ST. LUKE'S HOSPITAL, ANUA</h2> 
                            <p class="pl-5 pr-5">St. Lukes hospital is one of the foremost Missionary Hospitals in in South-South and South-East Nigeria. Its was established in 1937 by the Medical Missionaries of Mary with an initial number of twelve beds. Since then, it has... </p> 
                            <a (click)="scrollTo('#about')"  class="btn-get-started scrollto">Read More</a> 
                        </div>                         
                    </div>                     
                </div>                 
                <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> 
                <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> 
            </div>             
        </section>
        <!-- End Hero -->  
        
        
        <!--OPENING HOURS-->
        <div id="topbar" class="d-flex align-items-center pink-bg" > 
            <div class="container d-flex align-items-center justify-content-between"> 
                <div class="d-flex align-items-center" style="font-size: 0.8rem;"> 
                    <i class="icofont-clock-time" ></i> Monday - Sunday, 24hrs daily
                </div>                 
                <div class="d-flex align-items-center" style="font-size: 0.8rem;"> 
                    <i class="icofont-phone"></i> Call us now +234 802 328 7168
                </div>                 
            </div>             
        </div>   



        <main id="main"> 
            <!-- ======= Featured Services Section ======= -->             
            <section id="featured-services" class="featured-services"> 
                <div class="container" data-aos="fade-up"> 
                    <div class="row"> 
                        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="100"> 
                                <div class="icon">
                                    <i class="icofont-muscle-weight"></i>
                                </div>                                 
                                <h4 class="title"><a href="">Physiotherapy</a></h4> 
                                <p class="description" style="font-size: 0.86rem !important;"> Evident-Based Kinesiology, Exercise Prescription, Health Education, Orthopedic Cases, Neurological Cases, Gynaecological/Urological Cases, Paediatric Cases, Sports Rehabilitation, Cardiac Rehabilitation, etc.</p> 
                            </div>                             
                        </div>                         
                        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="200"> 
                                <div class="icon">
                                    <i class="icofont-laboratory"></i>
                                </div>                                 
                                <h4 class="title"><a href="">Laboratory</a></h4> 
                                <p class="description" style="font-size: 0.86rem !important;"> Our Laboratory handles investigations in the sub departments like Haematology, Chemistry, Hormone Profile, Microbiology/Parasitology, Gene Expert, Semenalysis, Serology. </p> 
                            </div>                             
                        </div>                         
                        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="300"> 
                                <div class="icon">
                                    <i class="icofont-operation-theater"></i>
                                </div>                                 
                                <h4 class="title"><a href="">Surgery</a></h4> 
                                <p class="description" style="font-size: 0.86rem !important;"> Surgery Department handles general surgical cases like Appendicectomy, Herniorrhaphy, Complicated Hernia, Laparatomy,  Spenectomy, Orchidectomy, Cholecystectomy, Hydrocelectomy, etc. </p> 
                            </div>                             
                        </div>                         
                        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="400"> 
                                <div class="icon">
                                    <i class="icofont-baby"></i>
                                </div>                                 
                                <h4 class="title"><a href="">OBS & GYNAE</a></h4> 
                                <p class="description" style="font-size: 0.86rem !important;"> Services include Routine Ante-Natal and Post-Natal care, Normal Deliveries, Caesarian Section, Episiotomy, cervical repairs, total and sub-total hysterectomy, Ectopic Pregnancy, Pelvic Floor Repair/Manchester, etc.</p> 
                            </div>                             
                        </div>                         
                    </div>                     
                </div>                 
            </section>
            <!-- End Featured Services Section -->             
         

            <!-- ======= Cta Section ======= -->             
            <section id="cta" class="cta vision-bg"> 
                <div class="container "> 

                    <div class="row visionMission">
                    <div class=" col-md-6 text-center mt-2 mb-2 p-3" data-aos="fade-right"> 
                        <h3 style="font-size:2.8rem; ">Vision</h3> 
                        <p class="mb-0">The vision of the catholic health services is to be the foremost agent of the health-for-all movement in Akwa Ibom State of Nigeria by providing holistic health care to one and all in the spirit of the Christ who came to do and to teach and was mighty in what he did and taught - so that men and women would have life and have it in all its fullness (Lk. 24:19).</p> 
                    </div>
                    <!--LINE -->  
                    
                    <div class="col-md-6 text-center mt-2 mb-2 p-3" data-aos="fade-left"> 
                            <h3 style="font-size:2.8rem; color: var(--pink-color)">Mission</h3> 
                            <p class="mb-0">To share in the healing ministry of Jesus Christ through the provision of health services that is holistic and affirms human dignity and the sacredness of life.</p> 
                        </div> 
</div>

                </div>                 
            </section>
            
            <!-- End Cta Section -->             
            <!-- ======= About Us Section ======= -->             
            <section id="about" class="about" style="background:rgba(13,71,161, 0.08); "> 
                <div class="container" data-aos="fade-up"> 
                    <div class="section-title"> 
                        <h2 style="color: var(--pink-color)">About Us</h2> 
                        <!-- accordion-->
                    </div>
                    <!--HISTORY AREA-->
                    <div>

                        <div class="pagination-content page-active" id="container-pagnation1">

                            <p class="npage-paragraph">
                                St. Luke’s Hospital is one of the foremost Missionary Hospitals in South-South and South Eastern Nigeria. It was established in 1937 by the Medical Missionaries of Mary (MMM) with an initial number of twelve beds. Since then, it has grown over the years to its present (Four Hundred) 400-beds. In fact, the Medical Missionaries of Mary (MMM) founded the hospital with the purpose to help the mother and child. Mother Mary’s inspiration arose from the number of women she saw suffering at childbirth. St. Luke’s Hospital, Anua, Uyo, is and has remained the flagship and pride of the Catholic Church East of the Niger.    
                            </p>
                            <p class="npage-paragraph">
                                In the early years of her operations, the General Medical Council in Lagos and London recognized the hospital as Centre for training and pre-registration of House Doctors.
                            </p>
                        </div>




                        <div class=" pagination-content" id="container-pagnation2">
                            <p class="npage-paragraph">
                                    Similarly, the School of Nursing during the same period was developed to attain an equal status with the Nursing School in the United Kingdom. This development qualified the successful students from St. Luke’s Hospital’s School of Nursing for the Registered Nurses Nigeria Certificate which was accepted by the British Nigeria Council. In the 1970s a new standard for Nurses Training was introduced in Nigeria and to the glory of God, St. Luke’s Hospital was the only Hospital recognized for training of nurses in the whole of South Eastern State of Nigeria. Admission into the School then was through a “National Entrance Test” conducted twice in a year by the Federal Government of Nigeria. Today, though the School of Nursing and Midwifery are under the management of the Akwa Ibom State Government, their quality standards are still very high. Equally, our Laboratory was recognized for the training of Medical Technology in Nigeria.
                            </p>
                        </div>




                        <div class=" pagination-content " id="container-pagnation3">

                            <p class="npage-paragraph">
                                Unfortunately, The Nigerian 3-year Civil War which ended in January 1970 left the hospital structures in a dreadful state of disrepair with virtually everything gone with the war. However, due to the generosity of the Misereor and the resourcefulness of the then Proprietor, Bishop B. D. Usanga of blessed memory, aids were obtained to rehabilitate the hospital, buy new equipment and carried out some repairs. It is to be recalled that some notable voluntary workers labored on the full and part time basis then to bring back the hospital to its former glory. Some of those workers were the St. John ambulance and Quaker Team.
                            </p> 

                        </div>




                        <div class="pagination-content" id="container-pagnation4">
                           
                            <p class="npage-paragraph">
                                At that time Doctors and Nurses from St. Luke’s Hospital routinely visited Medical outpost at St. Mary’s Hospital (Urua Akpan), Mount Carmel Hospital, (Akpa Utong), St. Theresa’s Hospital, Use Abat and other rural Clinics and Dispensaries in places like Eman Uruan, Ibiaku Uruan, Idu Uruan, Mbiaya Uruan, Mbak Etoi and Idoro, at regular intervals to deliver healthcare services.
                            </p>

                            <p class="npage-paragraph">
                                The Hospital being a faith-based organization is wholly owned by the Catholic Diocese of Uyo, Akwa Ibom State. It is strategically located in Anua Offot Uyo in the Central Business District of the State Capital.
                            </p>

                            <p class="npage-paragraph">
                                Corresponding to the government’s policy changes, the Akwa Ibom State government has at different times in the past, taken over and handed back the hospital. The hospital performs primary, secondary and, in few cases depending on the availability of Consultants selected tertiary services.
                            </p>

                        </div>



                        <div class="pagination-content" id="container-pagnation5">
                           
                                <p class="npage-paragraph">
                                    From a very humble beginning therefore, it is evident that the hospital has grown in Leaps and Bounds to the present state and status as an outstanding health care institution serving the whole of the State capital. At present, it takes referrals from other hospitals (both Government and Private) on maternity, surgery, accident and emergency units. There are also patients from the neigbouring States who patronize the hospital.
                                </p>
    
                                <p class="npage-paragraph">
                                    We salute the past and present contributions of Proprietors, Administrators, Chief Medical Directors, Matrons, Nurses, Doctors and other workers whose contributions immensely have taken the Hospital to an enviable height. We place on record Dr. Ann Ward’s (of Blessed Memory) invaluable help, in the past. We appreciate the efforts of our present Local Ordinary Most Rev. Dr. John E. Ayah and the Administrator Very Rev. Fr. Dr. Gabriel Ekong for the progress so far.
                                </p>
                        </div>



                    </div>
                    <ul class="pagination justify-content-center" style="cursor:pointer; color:initial"> 
                        <li id="link1" class="page-item active" (click)="gotoPage('container-pagnation1', 'link1')">
                            <a class="page-link">1</a>
                        </li>                         
                        <li id="link2" class="page-item" (click)="gotoPage('container-pagnation2', 'link2')">
                            <a class="page-link">2</a>
                        </li>                         
                        <li id="link3" class="page-item" (click)="gotoPage('container-pagnation3', 'link3')">
                            <a class="page-link">3</a>
                        </li>
                        <li id="link4" class="page-item" (click)="gotoPage('container-pagnation4', 'link4')">
                            <a class="page-link">4</a>
                        </li>

                        <li id="link5" class="page-item" (click)="gotoPage('container-pagnation5', 'link5')">
                                <a class="page-link">5</a>
                        </li>

                    </ul>
                </div>                 
            </section>
            <section id="donation" class=" featured-services pb-4 pt-4"> 
                <div class="container" data-aos="fade-up"> 
                    <div class="section-title"> 
                        <h2>MAKE DONATION</h2> 
                        <p class="text-center"> Highly spirited individuals have made contributions towards the growth and development of St. Luke’s Hospital, Anua. Your donation/s will assist in facilitating the ongoing humanitarian projects being carried out by the hospital for the good and ultimate benefits of the masses of our people. We appreciate the contributions of all our benefactors and benefactresses and we believe the following scriptures shall be fulfilled in their lives, “Give and it shall be given unto you, good measure, pressed down, shaken together, and running over, shall men give into your bosom ….. withal it shall be measured to you again. (Luke 6:38). A generous man will prosper; he who refreshes others will himself be refreshed, (Prov. 11:25)” </p> 
                    </div>                     
                    <div class="row"> 
                        <div class="col-md-12 col-lg-6 d-flex align-items-stretch mt-2 mb-lg-0"> 
                            <div class="icon-box w-100 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100"> 
                                <div style="margin: auto;" class="icon">
                                    <i class="icofont-medical-sign pink-white"></i>
                                </div>                                 
                                <h4 style="margin: auto; font-size: 1.2rem;" class="title p-2"><a class="pink-white" href="">Purpose of donation</a></h4> 
                                <p style="margin: auto;" class="description"> To support the various projects ongoing at St. Luke’s Hospital, Anua – Uyo, Akwa Ibom State, currently and those to be initiated according to need, (projects include infrastructure renaissance, equipment upgrade/replacement of obsolete ones, acquisition of new vehicles including ambulances, installation of ICT and capacity building for personnel </p> 
                            </div>                             
                        </div> 
                        
                        
                        <div class="col-md-12 col-lg-6 d-flex align-items-stretch mt-2 mb-lg-0 " style="min-height: 200px;"> 
                                <div  class="donate-bg w-100 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100"> 
                                </div>                             
                            </div> 


                    </div>




                    <div class="row pt-4 justify-content-center"> 
                        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="200"> 
                                <div class="icon">
                                    <i class="icofont-bank"></i>
                                </div>                                 
                                <h4 class="title"><a href="">Bank Name</a></h4> 
                                <p class="description"> First Bank of Nigeria Plc. </p> 
                            </div>                             
                        </div>
                        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="200"> 
                                <div class="icon">
                                    <i class="icofont-money"></i>
                                </div>                                 
                                <h4 class="title"><a href="">Account Number</a></h4> 
                                <p class="description"> 3006022991 </p> 
                            </div>                             
                        </div>
                        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"> 
                            <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="200"> 
                                <div class="icon">
                                    <i class="icofont-hospital"></i>
                                </div>                                 
                                <h4 class="title"><a href="">Beneficiary</a></h4> 
                                <p class="description"> St. Luke's Hospital, Anua </p> 
                            </div>                             
                        </div>                         
                    </div>                     
                </div>                 
            </section>

             <!-- ======= PROJECTS HEAD======= -->             
             <section id="projects" class="projects pb-1 pt-3"> 
                    <div class="container" data-aos="fade-up"> 
                        <div class="section-title"> 
                            <h2>Proposed Projects</h2> 
                            <p>This is the front building accomodating the casualty department , Doctor's Consulting Rooms and the Imaging Department. This building is the cynosure of the Hospital and requires a total facelift from its current near dilapidated state</p> 
                        </div>                     
    
                    </div>                 
                </section>
    <!-- NEW GALLERY SECTION -->    



    <div id="projects" class="container" data-aos="fade-up" data-aos-delay="100"> 
    <div class="card-columns" data-pg-collapsed> 
        
            <div  class="card img-card" *ngFor="let project of projects; let k=index;" > 
                <img (click)="viewProject(project, nameOfProjectPlace[k])" style="cursor: pointer;" class="card-img-top" [src]="project"  alt="Card image cap"> 
                <div class="card-body pb-2 pt-2"> 
                    <p class="card-text" style="color:black !important; font-size: 0.9rem;" > {{nameOfProjectPlace[k]}} </p> 
                </div>         
            </div>   
        
        </div>
    </div>

    <!-- END OF NEW PROJECTS SECTION -->



            <!-- End About Us Section -->             
        
            <!-- ======= Services Section ======= -->             
            <section id="services" class="services services"> 
                <div class="container" data-aos="fade-up"> 
                    <div class="section-title"> 
                        <h2>Services</h2> 
                        <p>Below are the Services offered in St. Luke’s Hospital, Anua.</p> 
                    </div>                     
                    <div class="row"> 
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100"> 
                            <div class="icon">
                                <i class="icofont-muscle-weight"></i>
                            </div>                             
                            <h4 class="title"><a href="">Physiotherapy</a></h4> 
                            <p class="description"> Evident-Based Kinesiology, Exercise Prescription, Health Education, Orthopedic Cases, Neurological Cases, Gynaecological/Urological Cases, Paediatric Cases, Sports Rehabilitation, Cardiac Rehabilitation, Pulmonary Rehabilitation, Fitness Training, Consultancy in Ergonomics, etc.
                                <span class="pink">For consultation, please contact our Physiotherapists.</span>
                                 </p> 
                        </div>                         
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200"> 
                            <div class="icon">
                                <i class="icofont-baby"></i>
                            </div>                             
                            <h4 class="title"><a href="">OBS &amp; Gynae</a></h4> 
                            <p class="description"> Services include Routine Ante-Natal and Post-Natal care, Normal Deliveries, Caesarian Section, Episiotomy, cervical repairs, total and sub-total hysterectomy, Ectopic Pregnancy, Pelvic Floor Repair/Manchester, Perineoraphy, Marsupialization, Cervical Polypectomy, Excision of Vulva Cyst and Condylomata, Endometrial Biopsy, Cervical Cerclage, Adhesiolysis, Cervical Biopsy, etc.
                                <span class="pink">For consultation, please contact our Obs & Gynae Doctors.</span>
                                 </p> 
                        </div>                         
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-crutch"></i>
                            </div>                             
                            <h4 class="title"><a href="">Orthopaedics</a></h4> 
                            <p class="description"> Close Manipulation Of Fracture, Skin Traction, Tendon Repair, Skeletal Traction, Correction of Deformities, Amputation/Disarticulation, Refrashioning of Stump, Polydactyly, Arthroscopy Surgery, Knee and Hip Replacement, Exactosis, etc.
                                <span class="pink">For consultation, please contact our Orthopaedic Doctors.</span>
                                 </p> 
                        </div>                         
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100"> 
                            <div class="icon">
                                <i class="icofont-doctor"></i>
                            </div>                             
                            <h4 class="title"><a href="">Internal Medicine</a></h4> 
                            <p class="description">Consultation, Counseling, Investigative Medicine, Rehabilitation, Follow-up, etc. For consultation. <span class="pink">please contact our Internal Medicine Doctors.</span></p> 
                        </div>                         
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200"> 
                            <div class="icon">
                                <i class="icofont-xray"></i>
                            </div>                             
                            <h4 class="title"><a href="">X-Ray</a></h4> 
                            <p class="description">Routine Examinations Like x-ray of the long bones, Skull, Cervical Spine, Thoracic, Lumbar, Chest X-Ray, etc.
                                For consultation. <span class="pink">please contact our Radiographer</span>
                                </p> 
                        </div>                         
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-laboratory"></i>
                            </div>                             
                            <h4 class="title"><a href="">Laboratory</a></h4> 
                            <p class="description"> Our Laboratory handles investigations in the sub departments like Haematology, Chemistry, Hormone Profile, Microbiology/Parasitology, Gene Expert, Semenalysis, Serology, etc.
                                <span class="pink">For consultation, please contact our Laboratory Scientists.</span>
                                 </p> 
                        </div>
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-operation-theater"></i>
                            </div>                             
                            <h4 class="title"><a href="">Surgery</a></h4> 
                            <p class="description"> Surgery Department handles general surgical cases like Appendicectomy, Herniorrhaphy, Complicated Hernia, Laparatomy,  Spenectomy, Orchidectomy, Cholecystectomy, Hydrocelectomy, Sequestrectomy, Haemorrhoidectomy, Mastectomy, etc.
                                For consultation <span class="pink"> please contact our Surgeons.</span>
                                 </p> 
                        </div>
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-icu"></i>
                            </div>                             
                            <h4 class="title"><a href="">Ultrasound</a></h4> 
                            <p class="description">  Examination of the Abdomen, Pelvis, Soft Tissue, Obstetric, Thyroid, Breast, Scrotum, etc. 
                                For consultation. <span class="pink"> please contact our Sonographers.</span>
                                 </p> 
                        </div>
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-eye"></i>
                            </div>                             
                            <h4 class="title"><a href="">Eye Clinic</a></h4> 
                            <p class="description"> Examination, Diagnosis, Refracting, Treatment of ocular pathologies, Lens fitting, etc. 
                                <span class="pink"> For consultation please contact our Ophthalmologist. </span>
                                 </p> 
                        </div>
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-tooth"></i>
                            </div>                             
                            <h4 class="title"><a href="">Dental Services</a></h4> 
                            <p class="description"> We provide all primary and secondary dental services.
                                For consultation. <span class="pink">please contact our Dentists.</span>
                                 </p> 
                        </div>
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-ambulance-cross"></i>
                            </div>                             
                            <h4 class="title"><a href="">Accident &amp; Emergency</a></h4> 
                            <p class="description"> The department is equipped to provide prompt and efficient emergency health care services aimed at reducing morbidity and mortality. 
                                <span class="pink">For consultation, please contact our Accident & Emergency Doctors</span>
                                 </p> 
                        </div>
                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300"> 
                            <div class="icon">
                                <i class="icofont-baby-cloth"></i>
                            </div>                             
                            <h4 class="title"><a href="">Paediatrics</a></h4> 
                            <p class="description"> Handles all cases pertaining to children.
                                <span class="pink">For consultation, please contact our Paediatricians.</span>
                                 </p> 
                        </div>                         
                    </div>                     
                </div>                 
            </section>

            <!-- End Services Section -->      
            
            

            <!-- ======= Appointment Section ======= -->             
            <section id="birth" class="appointment section-bg"> 
                <div class="container" data-aos="fade-up"> 


                    <div class="row">


                    

                    <div class="col-md-12 col-lg-6 pb-3 pt-3">
                    <div class="section-title"> 
                        <h2>People born at st. lukes</h2> 
                        <p>Find your name in St. Luke&apos;s Database</p>
                        
                    </div>                     
                    <form class="php-email-form" data-aos="fade-up" data-aos-delay="100" > 
                        <div class="form-row"> 
                            
                            <div class="col-6 offset-3 form-group w-100 mb-0"> 
                                <input (input)="search( babyName.value );" #babyName type="text" name="baby" class="form-control" id="name-field" placeholder="Please Enter Your Name" data-rule="minlen:1" data-msg="Please enter at least 1 character" style="font-size: 1.1rem; color:#050505; "> 
                                <div class="validate"></div>
                                <label style="color: var(--pink-color)"> {{searchResponse}} </label>                                 
                            </div>                             
                        </div>            

                        <div class="text-center">
                            <button class="nbutton" (click)="search( babyName.value );" style="outline:none;" type="button" >Search</button>
                        </div>     

                    </form>  
                    </div>


                    <div data-aos="fade-left" data-aos-delay="200" class="col-md-12 col-lg-6 baby-bg">
                        <label data-aos="zoom-in"  *ngIf="searchNotFound" style="border-radius: 20p; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: auto; height: auto; color:var(--blue-color-deep); font-weight: bold; " > Data not found! if you were born in this Hospital, kindly get in touch with us for your data to be verified and uploaded on our database. Thank you. </label>
                        
                        <div *ngIf="searchRecord.length>0" class="nscroll w-100" data-aos="zoom-in" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: auto; height: auto; max-height: 180px; overflow-y: auto; overflow-x: hidden;;">
                            <label class="w-100 m-2 p-2" style="color: #080808; font-weight: normal; background: rgba(13,71,161, 0.08);" *ngFor="let babyRecord of searchRecord"> {{babyRecord.name}} </label>
                        </div>
                    
                    </div>
                    

                </div>
                    

                </div>                 
            </section>
            <!-- End Appointment Section -->             
            <!-- ======= Departments Section ======= -->             
            <section id="departments" class="departments"> 
                <div class="container" data-aos="fade-up"> 
                    <div class="section-title"> 
                        <h2>Departments</h2> 
                        <p>The following core departments are in operation in St. Luke&apos;s hospital Anua. They are sub-divided into a number of wards, clinics and Support services.</p> 
                    </div>                     
                    <div class="row" data-aos="fade-up" data-aos-delay="100"> 
                        <div class="col-lg-4 mb-5 mb-lg-0"> 
                            <ul class="nav nav-tabs flex-column"> 
                                <li class="nav-item mt-2"> 
                                    <a class="nav-link active show" data-toggle="tab" href="#tab-2"> <h4>Administration and Finance</h4> <p> {{administration[0] + ", " + administration[1] + "..." }} </p> </a> 
                                </li>                                 
                                <li class="nav-item mt-2"> 
                                    <a class="nav-link" data-toggle="tab" href="#tab-3"> <h4>Medical/Surgical Wards</h4> <p> {{medicals[0] + ", " + medicals[1] + "..." }}  </p> </a> 
                                </li>                                 
                                <li class="nav-item mt-2"> 
                                    <a class="nav-link" data-toggle="tab" href="#tab-4"> <h4>Clinics</h4> <p>  {{clinics[0] + ", " + clinics[1] + "..." }}  </p> </a>
                                </li>                                 
                                <li class="nav-item mt-2"> 
                                    <a class="nav-link" data-toggle="tab" href="#tab-5"> <h4>Support Services</h4> <p> {{supportService[0] + ", " + supportService[1] + "..." }} </p> </a> 
                                </li>                                 
                            </ul>                             
                        </div>                         


                        <div class="col-lg-8"> 
                            <div class="tab-content"> 
                                <div class="tab-pane active show" id="tab-2"> 
                                    <div class="row"> 
                                                                                
                                        <!--start of list-->
                                        <div class="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left"> 
                                            <h3>Administration and Finance</h3> 
                                            <p class="font-italic">  </p> 
                                            <div class="card-columns" data-pg-collapsed>      
                                                    <div class="card p-0" *ngFor="let admin of administration"> 
                                                        <blockquote class="blockquote mb-0 card-body p-3"> 
                                                            <p> {{admin}} </p>
                                                        </blockquote>         
                                                    </div>                       
                                             </div>
                                        </div>                                         
                                        <!--end of list-->
                                    </div>                                     
                                    <!--end of row-->
                                </div>                                 
                                <!--START TAB 3-->
                                <div class="tab-pane" id="tab-3"> 
                                    <div class="row"> 

<!--start of list-->
                                        <div class="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left"> 
                                            <h3>Medical/Surgical Wards</h3> 
                                            <p class="font-italic"> </p> 


                                            <div class="card-columns" data-pg-collapsed>      
                                                    <div class="card p-0" *ngFor="let medical of medicals"> 
                                                        <blockquote class="blockquote mb-0 card-body p-3"> 
                                                            <p> {{medical}} </p>
                                                        </blockquote>         
                                                    </div>                       
                                             </div>

                                        </div>                                         
                                        <!--end of list-->
                                    </div>                                     
                                    <!--end of row-->
                                </div>                                 
                                <!--END TAB 3-->


                                <!--START TAB 4-->
                                <div class="tab-pane" id="tab-4"> 
                                    <div class="row"> 
                                        <!--start of list-->
                                        <div class="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left"> 
                                            <h3>Clinics</h3> 
                                            <p class="font-italic"></p> 

                                            <div class="card-columns" data-pg-collapsed>      
                                                    <div class="card p-0" *ngFor="let clinic of clinics"> 
                                                        <blockquote class="blockquote mb-0 card-body p-3"> 
                                                            <p> {{clinic}} </p>
                                                        </blockquote>         
                                                    </div>                       
                                             </div>
                                        </div>                                         
                                        <!--end of list-->
                                    </div>                                     
                                    <!--end of row-->
                                </div>                                 
                                <!--END TAB 4-->
                                <div class="tab-pane" id="tab-5"> 
                                    <div class="row"> 
                                        <!--start of list-->
                                        <div class="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left"> 
                                            <h3>Support services</h3> 
                                            <p class="font-italic">St. Lukes Hospital, Anua, Uyo provides the following support services.</p> 
                                            
                                            
                                            <div class="card-columns" data-pg-collapsed>      
                                                    <div class="card p-0" *ngFor="let support of supportService"> 
                                                        <blockquote class="blockquote mb-0 card-body p-3"> 
                                                            <p> {{support}} </p>
                                                        </blockquote>         
                                                    </div>                       
                                             </div>
                                        </div>                                         
                                        <!--end of list-->
                                    </div>                                     
                                    <!--end of row-->                                     
                                </div>                                 
                                <!--end of tab 5-->
                            </div>                             
                        </div>                         
                    </div>                     
                </div>                 
            </section>
            <!-- End Departments Section -->             
                     
            
            <!-- ======= Gallery HEAD======= -->             
            <section id="gallery" class="gallery pb-1 pt-0"> 
                <div class="container" data-aos="fade-up"> 
                    <div class="section-title"> 
                        <h2>Gallery</h2> 
                        <p class="p-0 m-0"></p> 
                    </div>                     

                </div>                 
            </section>


<!-- NEW GALLERY SECTION -->


<div id="gallery" class="container" data-aos="fade-up" data-aos-delay="100"> 

<div class="card-columns" data-pg-collapsed> 

        <div class="card img-card" *ngFor="let gallery of galleries; let i=index;"> 
            <img (click)="viewImage(gallery, nameOfGalleryPlace[i] )" style="cursor: pointer;" class="card-img-top" [src]="gallery"  alt="Card image cap"> 
            <div class="card-body pb-2 pt-2"> 
                <p class="card-text" style="color:black !important; font-size: 0.9rem"> {{nameOfGalleryPlace[i]}} </p> 
            </div>         
        </div>   
    
    </div>
</div>



<!-- END OF NEW GALLERY SECTION -->
      
        
            
            <!-- ======= Contact Section ======= -->             
            <section id="contact" class="contact"> 
                <div class="container"> 
                    <div class="section-title" data-aos="zoom-in" data-aos-delay="200"> 
                        <h2>Contact</h2> 
                        <p>St. Lukes Hospital is located in the heart of Uyo,the capital of Akwa Ibom State, Nigeria at a coordinate of 5.030416 latitude, 7.957755 longitude, and an elevation of 66m.</p> 
                    </div>                     
                </div>                 
                <div data-aos="zoom-in" data-aos-delay="200"> 
                    <iframe style="border:0; width: 100%; height: 400px;" id="gmap_canvas" src="https://maps.google.com/maps?q=Saint%20Luke%20General%20Hospital%20Nwaniba%20Road%20Uyo&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" allowfullscreen></iframe>
                </div>                 
                <div class="container"> 
                    <div class="row mt-5"> 
                        <div class="col-lg-12"> 
                            <div class="row"> 
                                <div class="col-md-4 mb-4" data-aos="zoom-in" data-aos-delay="200"> 
                                    <div class="info-box mt-4 h-100"> 
                                        <i  class="pink bx bx-map"></i> 
                                        <h3 class="pink">Our Address</h3> 
                                        <p class="black">St. Luke&apos;s Hospital, Anua. P.M.B. 3, Uyo. Akwa Ibom State. Nigeria</p> 
                                    </div>                                     
                                </div>                                 
                                <div class="col-md-4 mb-4" data-aos="zoom-in" data-aos-delay="200"> 
                                    <div class="info-box mt-4 h-100"> 
                                        <i class="pink bx bx-envelope"></i> 
                                        <h3 class="pink">Email Us</h3> 
                                        <p class="black">info@stlukeshospitalanua.org</p> 
                                    </div>                                     
                                </div>                                 
                                <div class="col-md-4 mb-4" data-aos="zoom-in" data-aos-delay="200"> 
                                    <div class="info-box mt-4 h-100"> 
                                        <i class="pink bx bx-phone-call"></i> 
                                        <h3 class="pink">Call Us</h3> 
                                        <p class="black">+234 802 328 7168<br></p> 
                                    </div>                                     
                                </div>                                 
                            </div>                             
                        </div>                         
                    </div>                     
                </div>                 
            </section>
            <!-- End Contact Section -->             




            <!-- ======= Services Section ======= -->             
            <section id="friends" class="services services"> 
                    <div class="container" data-aos="fade-up"> 

                        <div class="section-title"> 
                            <h2>Friends of St. Luke’s Hospital</h2> 
                            <p>Below are friends of St. Luke’s Hospital, Anua.</p> 
                        </div>                     
                        <div class="row"> 

                        <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100"  *ngFor="let friend of friends"> 
                            <i [ngClass]="friend.icofont" style="color: var(--blue-color); font-size: 1.5rem;"></i>                     
                            <h4 class="title"> {{ friend.name }} </h4> 
                        </div>  
                            
                        </div>                     
                    </div>                 
            </section>
            <!-- End Services Section -->    



        </main>



        <!-- End #main -->         
        <!-- ======= Footer ======= -->         
        <footer id="footer" data-aos="zoom-in" data-aos-delay="200" style="background: rgba(13,71,161, 0.1); "> 
            <div class="footer-top"> 
                <div class="container"> 
                    <div class="row"> 
                        <div class="col-lg-6 col-md-6"> 
                            <div class="footer-info black"> 
                                <h3 class="pink">St. Luke&apos;s Hospital, Anua</h3> 
                                <p>P.M.B. 3, Uyo. Akwa Ibom State. Nigeria.<br><br> <strong>Phone:</strong> +234 802 328 7168<br> <strong>Email:</strong> info@stlukeshospitalanua.org<br></p> 
                                <div class="social-links mt-3"> 
                                    <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> 
                                    <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a> 
                                    <a href="https://t.me/joinchart/ESVfoxdoTp7PQw-aM1uj6w" class="telegram"><i class="bx bxl-telegram"></i></a> 
                                </div>                                 
                            </div>                             
                        </div>                         
                        <div class="col-lg-6 col-md-6">
                            <div class="row"> 
                                <div class="col"> 
                                    <h3 class="pink" style="font-size:1.4rem; font-weight: bold">Useful Links</h3> 
                                </div>                                 
                            </div>
                            <div class="row">
                                <!--FOOTER LINK LEFT-->
                                <div class="col-lg-6 col-md-6 footer-links"> 
                                    <ul > 
                                        <li >
                                            <i class="bx bx-chevron-right"></i> 
                                            <a  (click)="scrollTo('#about')" class="black">About us</a>
                                        </li>                                         
                                        <li>
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#contact')"  class="black">Contact</a>
                                        </li>
                                        <li>
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#services')" class="black">Services</a>
                                        </li>                                         
                                        <li class=" pb-0">
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#projects')" class="black">Proposed Projects</a>
                                        </li>                                         
                                    </ul>
                                </div>                 
                                
                                
                                <!-- FOOTER LINKS RIGHT -->
                                <div class="col-lg-6 col-md-6 footer-links"> 
                                    <ul> 
                                        <li>
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#departments')"  class="black">Departments</a>
                                        </li>                                         
                                        <li>
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#gallery')"  class="black">Gallery</a>
                                        </li>                                         
                                        <li>
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#donation')"  class="black">Donate</a>
                                        </li>
                                        <li>
                                            <i class="bx bx-chevron-right"></i> 
                                            <a (click)="scrollTo('#birth')" class="black">Find Person</a>
                                        </li>                                         
                                    </ul>                                     
                                </div>                                 
                                <!--END OF FOOTER RIGHT-->
                            </div>
                        </div>
                    </div>                     
                </div>                 
            </div>             
            <div   class="container-fluid" style=" border-top: 1px solid rgba(13,71,161, 0.08) "> 
                <div class="copyright black"> 
                    &copy; Copyright 
                    <strong><span class="black" >St. Luke's Hospital, Anua</span></strong>. All Rights Reserved
                </div>                 
                <div class="credits black"> 
                    Designed by 
                    <a class="black" href="emmahnywills@gmail.com">DeepCode</a> 
                </div>                 
            </div>             
        </footer>
        <!-- End Footer -->         
              
        <a (click)="scrollTo('#hero')" class="back-to-top"><i style="border-radius:50%;" class="icofont-simple-up"></i></a> 
        <!-- Vendor JS Files -->         
        
    </body>     
</html>


<!-- GALLERY VIEW -->
<div class="imageView d-flex align-items-center justify-content-center" [ngClass]="{'showView': showGalleryView }">
    <li class="icofont-close-line closeButton" (click)="showGalleryView=false;"></li>
    <img style="max-width: 100%; " [src]="currentGalleryView">
    <div class="viewName p-2"> {{currentGalleryName}} </div>

</div>



<!-- Project VIEW -->
<div class="imageView d-flex align-items-center justify-content-center" [ngClass]="{'showView': showProjectView }">
        <li class="icofont-close-line closeButton" (click)="showProjectView=false;"></li>
        <img style="max-width: 100%; " [src]="currentProjectView">
        <div class="viewName p-2"> {{currentProjectName}} </div>
 </div>


<!-- PROGRESS DIALOG II -->
<div (click)="isProgress = false;" class="progress-dialog-box"  [ngClass]="{'show-progress-dialog':isProgress, 'hide-progress-dialog':!isProgress }" >

    <div (click)="stopEvent($event);" class="progress-dialog-box-content soft-shadow"> 
    
        <div class="ring lg soft-shadow" style="transition: 0.4s opacity;">
            {{ progressLabel }}<span><img></span>
        </div>
</div>
</div>
<!-- END OF PROGRESS-->