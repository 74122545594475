import { Component, OnInit } from '@angular/core';
import { Router, ParamMap } from '../../../../node_modules/@angular/router';
import { PhpService } from '../../services/php.service';
import { JsonService } from '../../services/json.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor( private router:Router, private php: PhpService, private jsonService: JsonService ) { 

  }
 
  ngOnInit(): void {

    //this.SERVER_ADDRESS = ""; 
    Promise.resolve().then( ()=>{

      this.getBirthRecords(); //update: search should take place at server side using php

      //this.isValid();

      this.getFriends();
      
    } )
  }

  

  validity = true;

  isProgress = false;
  progressLabel = "";

  error = "";
  births = [];
  isPageLoaded = true;

  friends = [];


  getFriends(){  

    let dataURL = './assets/friends/' + 'friends' + '.json';
    this.jsonService.get( dataURL,  {"responseType":"text"}  ).subscribe( data=>{

       this.friends = JSON.parse( data );

    }, err=>{
      this.error = 'record not found on this server. please contact the admin';
    } );
    
  }



  getBirthRecords(){

    
    let dataURL = './assets/birthrecords/' + 'cases' + '.json';
    this.jsonService.get( dataURL,  {"responseType":"text"}  ).subscribe( data=>{

       this.births = JSON.parse( data );

    }, err=>{
      this.error = 'birth record not found on this server. please contact the admin';
    } );
    
  }


  searchResponse = "";

  searchRecord = [];

  babyName = "";
  babyDay = "";
  babyMonth = "";
  babyYear = "";

  searchNotFound = "";

  search( babyName ){  

    Promise.resolve().then( ()=>{

      this.searchRecord = [];

      if( babyName.trim() !="" ){

        this.isProgress = true;  this.progressLabel = "";

        this.searchResponse = "";
        this.searchNotFound = "";
        this.searchResponse = "Please wait . . .";
        
        this.searchRecord = this.births.filter( function( birth ){
          return ( birth.name ).trim() !="" && ( birth.name.toLowerCase()).includes( babyName.toLowerCase() );
        } )

      
        this.searchResponse = "";
        if(this.searchRecord.length>0){ this.searchNotFound=""; }else{ this.searchNotFound="not found"; }

        this.isProgress = false;

      }else{
        this.searchResponse = "Please enter baby name"
      }
    } )

  }


  
  administration = [
    "General Administration (Personnel, Information and Strategy)",
    "Nursing Administration",
    "Health Information Management (Medical Records)",
    "Account and Internal Audit",
    "Electrical, Transport, Works and Maintenance",
    "Laundry/Sewing Services",
    "Security"
  ];


  medicals= [
    "Antenatal Ward",
    "Accident and Emergency",
    "Cancer",
    "Dialysis",
    "Paediatrics",
    "Female Medical",
    "Female Surgical",
    "Labour Ward",
    "Male Medical",
    "Male Surgical",
    "Maternity II",
    "Nursery",
    "Post-Natal",
    "Tuberculosis",
    "Operating Theatre"
  ]


  clinics = [
    "Antenatal Clinic",
    "HIV/Counseling Unit",
    "Dental Clinic",
    "Eye Clinic",
    "Physiotherapy",
    "Cardio-Diabetic",
    "Immunization Centre",
    "General Out-Patient Department",
    "Internal Medicine" ,
    "Surgery",
    "Paediatrics",
    "Gynaecology",
    "Casualty",
    "Fertility"
  ]

  supportService = [
    "Anaesthsiology",
    "Sterilization Centre",
    "Ultrasound/Scanning Medical Imaging",
    "Radiography",
    "Mortuary"
  ]



  galleries = ["./assets/pinegrow/img/gallery/gallery-1.jpg", "./assets/pinegrow/img/gallery/gallery-2.jpg", "./assets/pinegrow/img/gallery/gallery-3.jpg", "./assets/pinegrow/img/gallery/gallery-4.jpg", "./assets/pinegrow/img/gallery/gallery-5.jpg", "./assets/pinegrow/img/gallery/gallery-6.jpg", "./assets/pinegrow/img/gallery/gallery-7.jpg", "./assets/pinegrow/img/gallery/gallery-8.jpg", "./assets/pinegrow/img/gallery/gallery-9.jpg", "./assets/pinegrow/img/gallery/gallery-10.jpg"];
  nameOfGalleryPlace = ["Admin block","Front view of St. Luke's Hospital, Anua","Ambulance services block","Aerial view of FSW, FMW, & Labour Ward","Serene environment","Walkway to FSW","Children's ward and MMW", "Post Natal and Antenatal wards", "Dialysis Centre", "Dialysis Centre"]
  
  projects = ["./assets/pinegrow/img/projects/project-1.jpg","./assets/pinegrow/img/projects/project-2.jpg","./assets/pinegrow/img/projects/project-3.jpg"];
  nameOfProjectPlace = [ "Front view of OPD block","Back view of OPD block","Aerial view of OPD block" ];


  currentGalleryView = "";
  currentGalleryName = "";
  showGalleryView = false;


  viewImage(gallery, gname)
  {
    this.currentGalleryName = gname;
    this.currentGalleryView = gallery;
    this.showGalleryView = true;
  }


  currentProjectView = "";
  showProjectView = false;
  currentProjectName = "";

  viewProject(project, pname)
  {
    this.currentProjectName = pname;
    this.currentProjectView = project;
    this.showProjectView = true;
  }


  activeTap = "#hero";
  scrollTo(id){
    this.activeTap = id;
    document.querySelector(id).scrollIntoView( { behavior: 'smooth', block:'center'} );
  }


  gallery1 = "./assets/pinegrow/img/gallery/gallery-1.jpg";
  gotoPage( id, linkID ){
    for(var i=0; i<5; i++){        
        document.getElementById( "container-pagnation"+ (i+1) ).classList.remove('page-active');
        document.getElementById( "link"+ (i+1) ).classList.remove('active');
    }
       
    document.getElementById( id ).classList.add('page-active');
    document.getElementById( linkID ).classList.add('active');
}

stopEvent(event: Event){
  event.stopPropagation();
}


isValid(){

  var todayMonth =  ( new Date().getMonth() + 1 ) 
  var todatYear = new Date().getFullYear();

  if( todayMonth> 11 || todatYear > 2020 ){
    this.validity=false;
  }else{
    this.validity=true;
  }

}


}//end of class